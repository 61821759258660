import './App.css';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Link
} from "react-router-dom";
import '@ionic/react/css/core.css';
import { IonAlert, IonTitle, IonInput, IonContent, IonHeader, IonItem, IonList, IonToolbar, IonText, IonButton, IonCard, IonCardContent, useIonToast, IonFooter, IonImg } from '@ionic/react';
import UploadEntries from './Components/UploadEntries';
import UploadInteressents from './Components/UploadInteressents';

const Login = (state) => {
  const navigate = useNavigate();

  const lang = JSON.parse(localStorage.getItem('lang')) || {
    "en": {
      "username": "Username",
      "password": "Password",
      "required": "required",
      "password-user-empty": "Username and/or password empty",
      "login-fail": "Incorrect login information",
      "welcome": "Welcome",
      "scan": "Scan",
      "options": "Options",
      "manage-event": "Event verwalten",
      "manage-user": "Benutzer verwalten",
      "scanned-entries": "Scanned Entries",
      "scanned-interessents": "Booth visitors",
      "upload": "Upload",
      "delete": "Delete",
      "popup-warning": "Warning!",
      "delete-message": "By pressing DELETE, stored data will be permanently deleted!",
      "popup-back": "Back",
      "delete-confirm": "Delete",
      "logout-message": "Upload data before logging out!",
      "logout-confirm": "Upload and Logout"
    }
  };

  const [isLoggedIn, logIn] = React.useState(
    JSON.parse(localStorage.getItem('logged-in')) || false
  );
  const [eventData, setEventData] = React.useState(
    JSON.parse(localStorage.getItem('event-data')) || false
  );

  let currLang = lang["en"];
  if (isLoggedIn && eventData) {
    currLang = lang[eventData.data.eventData.language];
  }
  //console.log(currLang);

  const [scannedEntries, setEntries] = useState(JSON.parse(localStorage.getItem('scanned-entry')) || {})
  const [scannedInteressents, setInteressents] = useState(JSON.parse(localStorage.getItem('scanned-interessent')) || {})
  const [showAlert, setShowAlert] = useState(false);
  const [showAccess, setShowAccess] = useState(false);

  const [username, setUserName] = React.useState("");
  const [password, setUserPassword] = React.useState("");

  const logInUserResponse = (data) => {
    if (data.status == "success") {
      localStorage.setItem('logged-in', JSON.stringify(data));
      if (!localStorage.getItem("scanned-entry")) localStorage.setItem('scanned-entry', "{}");
      if (!localStorage.getItem("scanned-interessent")) localStorage.setItem('scanned-interessent', "{}");

      let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + data.event + '&option=read&value=event';
      fetch(prepareURL)
        .then(response => response.json())
        .then(json => loadEvent(json))
        .catch(error => presentToast(error));

      if (data.role == "admin") {
        prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=read&value=all_events';
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => loadEvents(json))
          .catch(error => presentToast(error));

        prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=read&value=scaner';
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => loadUser(json))
          .catch(error => presentToast(error));

        prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=read&value=companies';
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => loadCompanies(json))
          .catch(error => presentToast(error));
      }

      setUserName("");
      setUserPassword("");
      logIn(JSON.stringify(data));
      navigate("/", { replace: true });
    }
    if (data.status == "error") {
      presentToast(currLang["login-fail"]);
    }
  }

  const loadEvent = (data) => {
    if (data.status == "Success") {
      localStorage.setItem('event-data', JSON.stringify(data));
      setEventData(data);
    }
  }

  const loadUser = (data) => {
    localStorage.setItem('all-user', JSON.stringify(data));
  }
  const loadCompanies = (data) => {
    localStorage.setItem('all-companies', JSON.stringify(data));
  }
  const loadEvents = (data) => {
    localStorage.setItem('all-events', JSON.stringify(data));
  }

  const logOut = (status) => {
    const scannedEntries = JSON.parse(localStorage.getItem('scanned-entry')) || {};
    //setEntries(JSON.parse(localStorage.getItem('scanned-entry')) || {});
    const scannedInteressents = JSON.parse(localStorage.getItem('scanned-interessent')) || {};
    //setInteressents(JSON.parse(localStorage.getItem('scanned-interessents')) || {});

    if (Object.keys(scannedEntries).length > 0 || Object.keys(scannedInteressents).length > 0) {
      setShowAlert(true);
    } else {
      // remove all Setups
      localStorage.removeItem('scaner-id');
      localStorage.removeItem('all-events');
      localStorage.removeItem('all-user');

      localStorage.setItem('logged-in', false);
      logIn(false);
      localStorage.setItem('event-data', false);
      setEventData(false);

      navigate("/", { replace: true });
    }
  }

  const doLogOut = (status) => {
    //console.log(status);
    if (status == "confirm") {
      //console.log(scannedEntries, scannedInteressents);
      if (Object.keys(scannedEntries).length > 0) {
        let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=upload&value=entries&data=' + JSON.stringify(scannedEntries) + '&scaner_id=' + isLoggedIn.id;
        console.log(prepareURL);
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => uploadEntriesResponse(json))
          .catch(error => presentToast(error));
      }
      if (Object.keys(scannedInteressents).length > 0) {
        let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=upload&value=interessents&scaner_id=' + isLoggedIn.id + '&data=' + JSON.stringify(scannedInteressents);
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => uploadInteressentsResponse(json))
          .catch(error => presentToast(error));
      }
    }
    setShowAlert(false);
  }

  const navigateToVisitors = (status) => {
    const myID = JSON.parse(localStorage.getItem('logged-in')) || false;
    setShowAccess(false);
    let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=confirm&user=' + myID.id + '&pass=' + status.data.values.confirmPassword;
    //console.log(prepareURL);
    fetch(prepareURL)
      .then(response => response.json())
      .then(json => userAccessResponse(json))
      .catch(error => presentToast(error));
  }

  const loadAndShowVisitors = (data) => {
    localStorage.setItem("booth-visitors", JSON.stringify(data.data));
    navigate("/visitor", { replace: true });
  }

  const userAccessResponse = (data) => {
    const myID = JSON.parse(localStorage.getItem('logged-in')) || false;
    if(data.status == "success"){
      let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=' + myID.id + '&value=all_visitors';
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => loadAndShowVisitors(json))
          .catch(error => presentToast(error));
    }else{
      presentToast("Wrong password!");
    }
  }

  const uploadEntriesResponse = (input) => {
    if (input.response.status == "success") {
      localStorage.setItem('scanned-entry', '{}');
      setEntries({});

      localStorage.removeItem('scaner-id');
      localStorage.removeItem('all-events');
      localStorage.removeItem('all-user');

      localStorage.setItem('logged-in', false);
      logIn(false);
      localStorage.setItem('event-data', false);
      setEventData(false);
      logOut();
    } else {
      setEntries(input.response.resume);
    }
    //console.log(response.response);
  }

  const uploadInteressentsResponse = (input) => {
    if (input.response.status == "success") {
      localStorage.setItem('scanned-interessent', '{}');
      setInteressents({});

      localStorage.removeItem('scaner-id');
      localStorage.removeItem('all-events');
      localStorage.removeItem('all-user');

      localStorage.setItem('logged-in', false);
      logIn(false);
      localStorage.setItem('event-data', false);
      setEventData(false);
      logOut();
    } else {
      setInteressents(input.response.resume);
    }
    //console.log(response.response);
  }

  const scan = () => {
    navigate("/scan", { replace: true });
  }

  const user = () => {
    localStorage.setItem("scaner-id", 0);
    navigate("/user", { replace: true });
  }

  const event = () => {
    navigate("/event", { replace: true });
  }

  const accessVisitors = () => {
    setShowAccess(true);

  }
  const logInUser = () => {
    if (username == "" || password == "") {
      presentToast(currLang["password-user-empty"]);
    }
    if (username != "" || password != "") {
      if (oLN(username) && oLN(password)) {
        let prepareURL = 'https://medical-communications.congress-registration.com/badge/classes/jsRequestsPython.php?code=hg78tOvkbvgZ0bn5hbvk&id=1&option=login&user=' + username + '&pass=' + password;
        fetch(prepareURL)
          .then(response => response.json())
          .then(json => logInUserResponse(json))
          .catch(error => presentToast(error));
      } else {
        presentToast(currLang["login-fail"]);
      }
    }
  }

  const [present] = useIonToast();
  const presentToast = (custommessage) => {
    present({
      message: custommessage,
      duration: 1500,
      position: 'bottom',
    });
  };

  const oLN = (str) => {
    return /^[A-Za-z0-9]*$/.test(str);
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          {!isLoggedIn && <IonTitle>BSH Scaner Login</IonTitle>}
          {isLoggedIn && <IonTitle>{JSON.parse(localStorage.getItem('logged-in')).company} Leadscaner</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding loginform">
        <div>
          {!isLoggedIn && <IonCard class="centerLogin">
            <IonList>
              <IonItem>
                <IonInput type="text" labelPlacement="floating" onKeyUp={event => { setUserName(event.target.value); }} placeholder="Username" value={username}><div slot="label">{currLang["username"]} <IonText color="danger">({currLang["required"]})</IonText></div></IonInput>
              </IonItem>
              <IonItem>
                <IonInput type="password" labelPlacement="floating" onKeyUp={event => { setUserPassword(event.target.value); }} placeholder="Password"><div slot="label" value={password}>{currLang["password"]} <IonText color="danger">({currLang["required"]})</IonText></div></IonInput>
              </IonItem>
              <IonCardContent>
                <IonButton expand="full" onClick={logInUser} disabled={state.online === false}>Login</IonButton>
              </IonCardContent>
            </IonList>
          </IonCard>}
          {localStorage.getItem('logged-in') && JSON.parse(localStorage.getItem('logged-in')).company && JSON.parse(localStorage.getItem('logged-in')).company != "Logi-Vent" && <IonImg class="logo" src={"https://bsh-scanner.congress-registration.com/images/" + JSON.parse(localStorage.getItem('logged-in')).company + ".png"} />}

          {isLoggedIn && <IonCard class="loggedin">
            <h2>{currLang["welcome"]} {JSON.parse(localStorage.getItem('logged-in')).username}</h2>
            <IonButton expand="full" onClick={scan}>{currLang["scan"]}</IonButton>
          </IonCard>}

          {isLoggedIn && JSON.parse(localStorage.getItem('logged-in')).role == "sponsor" && <IonCard class="loggedin">
            <h2>{currLang["options"]}</h2>
            <IonButton expand="full" onClick={accessVisitors}>Manage visitors</IonButton>
          </IonCard>}
          {showAccess ? <IonAlert
            message="Please confirm your password!"
            isOpen={showAccess}
            buttons={[
              {
                text: currLang["popup-back"],
                role: 'cancel',
                handler: () => {

                },
              },
              {
                text: "confirm",
                role: 'confirm',
                handler: () => {

                },
              },

            ]}
            inputs={[
              {
                name: 'confirmPassword',
                placeholder: 'Password',
              },
            ]}
            onDidDismiss={({ detail }) => navigateToVisitors(detail)}
          ></IonAlert> : null}

          {isLoggedIn && JSON.parse(localStorage.getItem('logged-in')).role == "admin" && <IonCard class="loggedin">
            <h2>{currLang["options"]}</h2>
            <IonButton expand="full" onClick={event}>{currLang["manage-event"]}</IonButton>
            <IonButton expand="full" onClick={user}>{currLang["manage-user"]}</IonButton>
          </IonCard>}

          {isLoggedIn && JSON.parse(localStorage.getItem('logged-in')).role == "scaner" && <IonCard class="loggedin">
            <h2>{currLang["options"]}</h2>
            <IonButton expand="full" onClick={event}>{currLang["manage-event"]}</IonButton>
          </IonCard>}

          {isLoggedIn && <UploadEntries />}
          {isLoggedIn && <UploadInteressents />}
          {showAlert ? <IonAlert
            header={currLang["popup-warning"]}
            message={currLang["logout-message"]}
            isOpen={showAlert}
            buttons={[
              {
                text: currLang["popup-back"],
                role: 'cancel',
                handler: () => {

                },
              },
              {
                text: currLang["logout-confirm"],
                role: 'confirm',
                handler: () => {

                },
              },
            ]}
            onDidDismiss={({ detail }) => doLogOut(detail.role)}
          ></IonAlert> : null}
        </div>
      </IonContent>
      {isLoggedIn && <IonFooter>
        <IonToolbar class="loggedin">
          <IonButton expand="full" id="open-data" color="danger" onClick={logOut}>Logout</IonButton>
        </IonToolbar>
      </IonFooter>}
    </>
  );
}

export default Login;
